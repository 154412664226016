import { reactive, toRefs } from "vue";

function useTabOpen(){
    const tabStatus = reactive({
        tab1: false,
        tab2: false,
    });
    const tabStatusToRefs = toRefs(tabStatus);
        
    const OpenTab=(tab)=>{
        tabStatus[tab] = false;
    }
    const CloseTab=()=>{
        tabStatus.tab1 = false;
        setTimeout(()=>{
            tabStatus.tab2 = false;
        },500);
    }
    return { ...tabStatusToRefs, OpenTab, CloseTab };
}

export default useTabOpen