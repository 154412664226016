import { ref, reactive, watch } from 'vue';
import { basePost } from '@/js/services/baseService';
import { flashMessage } from '@/js/swal';

function useOrderLocation(orderId) {
    // 加強部位
    const orderLocation = reactive({ data: {} });
    // 是否有修改過加強部位
    const orderLocationChangeNum = ref(0);

    // 儲存加強部位
    const saveLocation = async (showMessage = true) => {
        const res = await basePost('/api_order/order_location_update', {
            order_id: orderId.value,
            location: JSON.stringify(orderLocation.data),
        });

        const { status, data } = res;

        if (status === 200 && data.res_code === 1) {
            if (!showMessage) return;
            flashMessage('修改加強部位成功');
            orderLocationChangeNum.value = 0;
        }
    };

    watch(orderLocation, () => {
        orderLocationChangeNum.value += 1;
    });

    return { orderLocation, orderLocationChangeNum, saveLocation };
}

export default useOrderLocation;
